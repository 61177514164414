
  async function enviarFormulario() {
  

    // Capturar los datos del formulario
    const name = document.getElementById("name").value;
    const cognoms = document.getElementById("cognoms").value;
    const phone = document.getElementById("phone").value;
    const email = document.getElementById("email").value;

    const recaptchaResponse = grecaptcha.getResponse();

      if (!recaptchaResponse) {
        alert("Per favor, verifica el reCAPTCHA.");
        return;
      }


    const spiner = document.getElementById("spiner");

    // Endpoint del Apps Script (coloca aquí tu URL)
    const endpoint = "https://script.google.com/macros/s/AKfycbx6dvkmHINey2e4t7JJRzDl-gS0GUxy-Y14ktR-gjZ9MBbIEykGRajz1acbN2kQvlqz/exec";

    //activar spiner
    spiner.classList.remove('d-none')
    // Enviar los datos mediante fetch
    try {
      const respuesta = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        crossDomain: true,
        body: JSON.stringify({ name, cognoms, phone, email }),
      });

      const resultado = await respuesta.json();
      if (resultado.status === "success") {
        location.href = 'success.html';
      } else {
        alert("Error al enviar datos: " + resultado.message);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error al enviar los datos.");
    }
  }
